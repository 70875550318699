import React from 'react'
import { graphql } from 'gatsby'
import { Container, ListItem, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
	CalendarToday
} from '@material-ui/icons'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Table from '../components/CourseTable'
import { PageTitle } from '../components/PageTitle'
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import StreetviewIcon from '@material-ui/icons/Streetview';
import LocationCityIcon from '@material-ui/icons/LocationCity';

const useStyles = makeStyles(theme => ({
	xLarge: {
		width: theme.spacing(12),
		height: theme.spacing(12),
	},
	m1: {
		margin: '10px 0',
	},
	floatRight: {
		float: 'right',
	},
	text: {
		color: 'rgb(102, 102, 102)',
		lineHeight: '1.85em',
		wordSpacing: '5px',
		textAlign: 'justify',
	},
	subscibeSection: {
		margin: '20px 0',
		display: 'flex',
		flexDirection: 'column',
		width: 'fit-content',
	},
	subscribeButton: {
		margin: '5px 0',
	},
	linkMatriz: {
		display: 'flex',
		fontSize: 20,
		color: '#007bff',
		width: 'fit-content',
		'&:hover': {
			textDecoration: 'underline !important'
		}
	}
}))

export default ({ data }) => {
	const post = data.markdownRemark
	const classes = useStyles()
	const state = post.frontmatter.state
	const city = post.frontmatter.city
	const address = post.frontmatter.address

	return (
		<Layout>
			<SEO title={post.frontmatter.name} />
			<Container disableGutters={true} maxWidth="md" style={{ marginTop: 60 }}>
				<PageTitle>{post.frontmatter.name}</PageTitle>
				<Grid container>
					<Grid lg={12} md={12}>
						<ListItem disableGutters="true" style={{ marginTop: 10 }}>
							<CalendarToday fontSize="small" /> Estado:{' '}
							{post.frontmatter.state}
						</ListItem>
						<ListItem disableGutters="true">
							<LocationCityIcon /> Cidade: {post.frontmatter.city}
						</ListItem>
						<ListItem disableGutters="true">
							<StreetviewIcon /> Endereço: {post.frontmatter.address}
            </ListItem>
						<ListItem disableGutters="true">
							<LocationSearchingIcon /> Bairro: {post.frontmatter.district}
						</ListItem>

					</Grid>
				</Grid>
			</Container>
		</Layout>
	)
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        name
        category
        state
        city
        district
        address
      }
      html
    }
  }
`
